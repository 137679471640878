<template>
  <div class="package-box p-2 d-flex flex-column justify-content-between">
    <div class="top-box">
      <p>{{ packageTestName }}</p>
    </div>
    <!-- <p class="mid-p">Includes: 105 parameters</p>
    <div class="mid-box">
      <div class="d-flex">
        <b-icon icon="caret-right-fill" class="icon-size"></b-icon>
        <p class="small-p ml-2">Alpha Feto Protein (AFP), Serum</p>
      </div>
      <div class="d-flex">
        <b-icon icon="caret-right-fill" class="icon-size"></b-icon>
        <p class="small-p ml-2">Amylase Enzymatic, Serum</p>
      </div>
      <div class="d-flex">
        <b-icon icon="caret-right-fill" class="icon-size"></b-icon>
        <p class="small-p ml-2">Anti HCV Total Antibody by CMIA Method</p>
      </div>
    </div> -->
    <div class="bottom-box">
      <div class="view-box d-flex justify-content-end">
        <div id="star8" class="ml-2 mr-4" v-if="discount">
          <p>{{ discount }}% Off</p>
        </div>
        <div class="d-flex align-items-center ml-3 mr-2">
          <del
            class="mr-2"
            v-if="product.mrp !== null && product.mrp != product.price"
            >{{ product.price }}</del
          >
          <p class="mrp" v-if="product.mrp !== null">{{ product.mrp }}</p>
          <p class="mrp" v-else>{{ product.price }}</p>
        </div>
      </div>
      <div
        class="book-box d-flex mt-2 justify-content-between align-items-center"
      >
        <router-link
          style="text-decoration: none; color: inherit"
          :to="viewProduct"
        >
          <p class="view">View Detail</p>
        </router-link>
        <router-link :to="bookItem">
          <div
            class="book-btn p-2 d-flex align-items-center justify-content-center"
          >
            <p>Book Now</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "healthian-package",
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    viewProduct: {
      type: Object,
      default: () => {},
    },
    bookItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    discount() {
      if (this.product.mrp !== null) {
        const mainPrice = Number(this.product.price);
        const markedPrice = Number(this.product.mrp);
        const discountPercent = ((mainPrice - markedPrice) / mainPrice) * 100;
        return Math.trunc(discountPercent);
      } else {
        return false;
      }
    },
    packageTestName() {
      if (this.product.test_name.length > 25) {
        return this.product.test_name.substring(0, 25) + "...";
      } else {
        return this.product.test_name;
      }
    },
  },
};
</script>

<style scoped>
.package-box {
  background-color: #fff;
  border: 1px solid #272727;
  border-radius: 10px;
  width: 220px;
  min-height: 160px;
}
.top-box p {
  font-size: 15px;
  font-weight: 600;
}
.icon-size {
  margin-top: 3px;
  height: 10px;
  width: 10px;
}
.mid-p {
  font-size: 13px;
  margin-bottom: 10px;
}
.small-p {
  font-size: 12px;
  margin-bottom: 7px;
}
del {
  font-size: 15px;
  font-weight: 500;
  color: #939393;
}
.view-box P {
  margin-bottom: 0px;
  font-weight: 500;
}
.mrp {
  font-size: 21px;
  font-weight: 600;
  color: #2a8750;
}
.book-box {
  line-height: 40px;
}
.book-box p {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 500;
}
.book-btn {
  background-color: #83abff;
  color: #fff;
  border-radius: 4px;
  height: 34px;
}
a {
  text-decoration: none !important;
}
.book-btn p {
  margin-bottom: 0px;
}
.view {
  text-decoration: underline;
  cursor: pointer;
  color: #0038ab;
}
.book-btn {
  font-size: 12px;
}
#star8 {
  border-radius: 6px;
  background: red;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotate(00deg);
  -moz-transform: rotate(00deg);
  -ms-transform: rotate(00deg);
  -o-transform: rotate(00deg);
}
#star8:before {
  border-radius: 6px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  background: red;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

#star8 p {
  position: absolute;
  margin: 0;
  z-index: 9;
  color: #fff;
  top: 10%;
  word-wrap: break-word;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 16px;
}
</style>
