var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "package-box p-2 d-flex flex-column justify-content-between"
  }, [_c('div', {
    staticClass: "top-box"
  }, [_c('p', [_vm._v(_vm._s(_vm.packageTestName))])]), _c('div', {
    staticClass: "bottom-box"
  }, [_c('div', {
    staticClass: "view-box d-flex justify-content-end"
  }, [_vm.discount ? _c('div', {
    staticClass: "ml-2 mr-4",
    attrs: {
      "id": "star8"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.discount) + "% Off")])]) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center ml-3 mr-2"
  }, [_vm.product.mrp !== null && _vm.product.mrp != _vm.product.price ? _c('del', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.product.price))]) : _vm._e(), _vm.product.mrp !== null ? _c('p', {
    staticClass: "mrp"
  }, [_vm._v(_vm._s(_vm.product.mrp))]) : _c('p', {
    staticClass: "mrp"
  }, [_vm._v(_vm._s(_vm.product.price))])])]), _c('div', {
    staticClass: "book-box d-flex mt-2 justify-content-between align-items-center"
  }, [_c('router-link', {
    staticStyle: {
      "text-decoration": "none",
      "color": "inherit"
    },
    attrs: {
      "to": _vm.viewProduct
    }
  }, [_c('p', {
    staticClass: "view"
  }, [_vm._v("View Detail")])]), _c('router-link', {
    attrs: {
      "to": _vm.bookItem
    }
  }, [_c('div', {
    staticClass: "book-btn p-2 d-flex align-items-center justify-content-center"
  }, [_c('p', [_vm._v("Book Now")])])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }